import React from "react";
import { Button, Input } from "@pai-ui/core";

const PasswordPage = ({ verifyPassword, setPassword, error, password }) => {
    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                margin: "0px 40% 0px 40%",
                gap: "10px",
                flexDirection: "column",
                // backgroundColor: "#111419",
            }}
        >
            <div
                style={{
                    margin: "0",
                    color: "hsla(0,0%,100%,.85)",
                    fontSize: "14px",
                    fontFamily: "SF Text",
                    fontVariant: "tabular-nums",
                    lineHeight: "1.5715",
                    backgroundColor: "#111419",
                }}
            >
                {" "}
                Maverick V2 Dev is password protected.{" "}
            </div>
            <Input
                style={{
                    background: "#2d2f31",
                    height: "30px",
                    border: "1px solid #3f4c58 !important",
                    borderRadius: "5px",
                    padding: "2px",
                    width: "100%",
                }}
                type="password"
                placeholder="Password"
                id="password"
                onChange={(e) => {
                    setPassword(e.target.value);
                }}
            />
            <div
                style={{
                    margin: "0",
                    color: "red",
                    fontSize: "14px",
                    fontFamily: "SF Text",
                    fontVariant: "tabular-nums",
                    lineHeight: "1.5715",
                    backgroundColor: "#111419",
                }}
            >
                {" "}
                {error}{" "}{" "}
            </div>
            <Button
                label="Submit"
                style={{
                    marginTop: "10px",
                    display: "flex",
                    float: "right",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                onClick={() => verifyPassword(password)}
            ></Button>
        </div>
    )
}

export default PasswordPage;